import { phoneNumber } from "@/constants/home/contactData";

function ExpiryContainerSV({ daysLeft, trialDate, pageType }) {
  return (
    <div>
      {daysLeft < 7 && daysLeft > 0 && daysLeft !== null && trialDate && (
        <div
          className={`z-[999] w-full bg-lightRed fixed top-[70px] lg:block flex flex-col justify-between
        ${
          pageType === "guest"
            ? "lg:shadow-none shadow-header-mobile "
            : " shadow-header-mobile  "
        }`}>
          {trialDate && (
            <p className="text-center mt-1 text-white">
              Your plan will expire on <b>{trialDate}</b>.
            </p>
          )}
          <p className="text-center mb-1 text-white">
            Please contact us to renew your plan.{" "}
            <a href={`tel:+91 ${phoneNumber}`} className="underline font-bold">
              {phoneNumber}
            </a>
          </p>
        </div>
      )}
    </div>
  );
}

export default ExpiryContainerSV;
