function Logo({ bgRed }) {
  return (
    <img
      src={
        bgRed
          ? "https://id-static-assets.s3.ap-south-1.amazonaws.com/logo-red-bg.png"
          : "https://id-static-assets.s3.ap-south-1.amazonaws.com/logo-white-bg.png"
      }
      alt="logo"
      className="w-[13rem] h-full object-contain"
    />
  );
}

export default Logo;
