import { useEffect, useState } from "react";
import Image from "next/image";
import { ChevronDown, ChevronRight, ChevronUp, X } from "lucide-react";
import Cookies from "js-cookie";
import VideoItems from "../../constants/header/video-nav.json";
import PDFItems from "../../constants/header/pdf-nav.json";
import Logo from "./logo";
import { removeToken } from "@/utils/auth-token";

function MobileNavItemsSV({
  navItems,
  router,
  showDropDown,
  setShowDropDown,
  toggleDrawer,
  showLogin,
  pageType,
  handleClose,
}) {
  const [items, setItems] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const jwtToken = Cookies.get("auth_token");
  const handleNavItems = (item) => {
    setItems(item);
    setBreadcrumbs([...breadcrumbs, item]);
  };

  const moveByBreadcrumb = (itemName) => {
    const itemMap = {
      "Video Invitation": VideoItems,
      "Pdf Invitation": PDFItems,
      //   "Website Invitation": WebsiteItems,
    };

    if (itemMap[itemName]) {
      setItems(itemMap[itemName]);
      setBreadcrumbs([{ name: itemName }]);
      return;
    }

    const index = breadcrumbs.findIndex((item) => item.name === itemName);
    setItems(breadcrumbs[index]);
    setBreadcrumbs(breadcrumbs.slice(0, index + 1));
  };

  useEffect(() => {
    const itemMap = {
      video: VideoItems,
      pdf: PDFItems,
      //   website: WebsiteItems,
    };
    let type;
    if (router.query?.invites)
      type = Object.keys(itemMap).find((key) =>
        router.query?.invites.includes(key)
      );
    else
      type = Object.keys(itemMap).find((key) => router.pathname.includes(key));

    if (type) {
      setItems(itemMap[type]);
      setBreadcrumbs([
        { name: `${type.charAt(0).toUpperCase()}${type.slice(1)} Invitation` },
      ]);
    }
  }, [router]);

  const renderBreadcrumb = (breadcrumbsItem) => (
    <div key={breadcrumbsItem?.name} className="flex items-center">
      <button
        onClick={() => moveByBreadcrumb(breadcrumbsItem?.name)}
        className={`flex justify-between items-center text-lg  cursor-pointer text-[#264065] hover:text-lightRed  
            ${breadcrumbsItem.name === items?.name ? "text-lightRed" : ""}
                `}
      >
        {breadcrumbsItem?.name}
      </button>
      {breadcrumbs.length > 1 && <ChevronRight size={20} />}
    </div>
  );

  return (
    <nav className="sm:hidden">
      {pageType !== "designs" && (
        <div className="flex justify-between items-center w-full">
          <a href="/">
            <Logo />
          </a>
          <button onClick={handleClose}>
            <X className="-mt-2 text-[#00000060]" />
          </button>
        </div>
      )}
      {pageType === "designs" && (
        <div className="flex justify-between items-center">
          <div className="flex flex-row flex-wrap">
            {breadcrumbs.map(renderBreadcrumb)}
          </div>
          <button onClick={handleClose}>
            <X className="mt-0.5 text-[#00000060]" />
          </button>
        </div>
      )}
      <hr className="mt-2"></hr>
      {pageType !== "designs" &&
        navItems
          .filter((item) => item && item.id)
          ?.map((item, index) => (
            <div key={index}>
              {item.isDropdown ? (
                <div>
                  <button
                    onClick={() =>
                      setShowDropDown({
                        ...showDropDown,
                        [item.id]: !showDropDown[item.id],
                      })
                    }
                    className={`flex justify-between items-center w-full text-lg  cursor-pointer
                 text-[#264065] hover:text-lightRed mb-2 mt-2
                 ${showDropDown[item.id] ? "text-lightRed" : ""}
                 `}
                  >
                    {item?.title}
                    {showDropDown[item.id] ? (
                      <ChevronUp className="ml-2" size={20} />
                    ) : (
                      <ChevronDown className="ml-2" size={20} />
                    )}
                  </button>
                  {showDropDown[item.id] && (
                    <div>
                      {item?.dropdownData?.map((itemDropdown, indexItem) => {
                        return (
                          <a
                            key={indexItem}
                            className={`block text-sm cursor-pointer pl-4 text-[#264065] hover:text-lightRed mb-2 mt-2
                            ${
                              router.query.invites === itemDropdown?.url ||
                              router.pathname === itemDropdown?.url
                                ? "text-lightRed"
                                : "text-[#2d4c76]"
                            }`}
                            href={itemDropdown?.url}
                          >
                            {itemDropdown?.title}
                          </a>
                        );
                      })}
                    </div>
                  )}
                </div>
              ) : (
                <a
                  href={item?.url}
                  className={`block text-lg cursor-pointer text-[#264065] hover:text-lightRed mb-2 mt-2

                  ${
                    router.query.invites === item?.url ||
                    router.pathname === item?.url
                      ? "text-lightRed"
                      : "text-[#2d4c76]"
                  }`}
                  onClick={toggleDrawer}
                >
                  {item?.title}
                </a>
              )}
            </div>
          ))}
      {pageType === "designs" && (
        <div>
          {items?.children?.map((item, indexChild) => (
            <div key={indexChild}>
              {item.children ? (
                <div>
                  <div
                    onClick={() =>
                      setShowDropDown({
                        ...showDropDown,
                        [item.name]: !showDropDown[item.name],
                      })
                    }
                    className={`flex justify-between items-center w-full text-lg  cursor-pointer
                     text-[#264065] hover:text-lightRed mb-2 mt-2
                     ${showDropDown[item.name] ? "text-lightRed" : ""}
                     `}
                  >
                    {item?.name}

                    {item.children.length > 0 && (
                      <button onClick={() => handleNavItems(item)}>
                        <ChevronRight className="ml-2" size={20} />
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                <a
                  href={item?.uri}
                  className={`block text-lg cursor-pointer text-[#264065] hover:text-lightRed mb-2 mt-2
                      ${
                        router.query.invites === item?.url ||
                        router.pathname === item?.url
                          ? "text-lightRed"
                          : "text-[#2d4c76]"
                      }`}
                  onClick={toggleDrawer}
                >
                  {item?.name}
                </a>
              )}
            </div>
          ))}
        </div>
      )}
      <hr className="mt-4 mb-4"></hr>
      {/* <a className="text-lg cursor-pointer" href="/">
        Home
        </a>
      <hr className="mt-4 mb-2"></hr> */}
      {jwtToken && (
        <h1
          className="text-lg cursor-pointer"
          onClick={() => {
            removeToken();
            window.location.href = "/";
          }}
        >
          Logout
        </h1>
      )}
      <div className="flex items-center gap-2 mt-4">
        <Image
          src="/assets/icon/phone.png"
          width={1000}
          height={1000}
          alt="phone"
          className="w-auto h-[20px] object-contain"
        />
        <a
          href="tel:+7895127272"
          className="font-medium text-[#264065] text-base"
        >
          {" "}
          +91 7895127272
        </a>
      </div>
    </nav>
  );
}

export default MobileNavItemsSV;
